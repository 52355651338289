<template>
  <object
    scroll="no"
    style="width: 100%; height: 100vh; overflow-x: hidden !important"
    scrolling="no"
    type="text/html"
    data="https://app.powerbi.com/view?r=eyJrIjoiZTcxNzQ4ZWYtOWY1NC00OTgwLTkwMWEtZTgzNzYyN2FjM2EzIiwidCI6ImMzNmJlZGI5LTAwMzgtNDM0OC1iYzRjLTg4ZWQ4MWZjMTQ1OCIsImMiOjR9"
  ></object>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TaxonomiaActivosFijos",
  data() {
    return {};
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Taxonomía de activos fijos")) this.$router.push("/dashboard");
  },
};
</script>

<style>
</style>
